<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2" style="width: 100%">
          <div class="d-inline-flex align-center py-2">
            <v-text-field
              v-model="search"
              class="pl-2"
              outlined
              clearable
              dense
              hide-details
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <v-btn
              text
              color="primary"
              class="ml-2"
              @click="$emit('fetchRoles', { options: options, search: search.length > 1 ? search : '' })"
            ><v-icon>mdi-refresh</v-icon>
            </v-btn></div>
          <v-btn v-if="roles.includes('ROLE_CREATOR')" color="accent" class="color-accent-text" @click="$router.push({ name: 'roleCreate' })">{{ $lang.actions.create }}</v-btn>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.labels.yes : $lang.labels.no }}</div>
      </template>

      <template v-slot:item.isAssignableToUser="{ item }">
        <div>{{ item.isAssignableToUser ? $lang.labels.yes : $lang.labels.no }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn :disabled="item.isSystem" color="primary" class="mx-0 button-default-width" :to="{ name: 'roleEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
          <!--          <v-btn :disabled="item.isSystem" color="primary" class="mx-0 button-default-width" @click="selectedItem = item; showNameChange = true">{{ $lang.actions.edit }}</v-btn>-->
          <v-btn
            v-if="roles.includes('ROLE_CREATOR')"
            color="accent"
            class="ml-1 button-default-width color-accent-text"
            @click="copyCreateResource(item, 'roleCreate', $router, '', $options.filters.formatDateTime(new Date()))"
          >{{ $lang.actions.copyCreate }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="showNameChange" v-model="showNameChange" max-width="33%">
      <name-modal :item="selectedItem" @update="saveRole" @closeDialog="showNameChange = false; selectedItem = null"></name-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import NameModal from './RoleNameModal'
import { updateRoleUsingPUT as updateRole } from '@/utils/api'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'

export default {
  components: {
    NameModal
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showNameChange: false,
      selectedItem: null,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.system, value: 'isSystem' },
        { text: this.$lang.labels.assignable, value: 'isAssignableToUser' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchRoles', { options: this.options, search: this.search.length > 1 ? this.search : '' })
      },
      deep: true
    },
    search: {
      handler (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchRoles', { options: this.options, search: val, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchRoles', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchRoles', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(3, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.roleList) {
        this.search = preFill.roleList.search
      }
    }
    this.$emit('fetchRoles', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    copyCreateResource,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.roleList) preFill.roleList = {
          search: ''
        }

        preFill.roleList.search = this.search

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    async saveRole(newName) {
      this.lock = true

      let res = null

      const { id } = this.selectedItem

      try {

        res = await updateRole({ id, request: { name: newName } })

        this.showNameChange = false

        if (res && res.status !== 200) {
          this.err = this.$lang.errors.roleUpdate
          setTimeout(() => this.err = '', 5000)
          this.selectedItem = null
          this.lock = false

          return
        }
        this.$emit('updateName', { id, name: newName })
        this.success = this.$lang.success.roleUpdated
        this.selectedItem = null

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
